/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Box, Container} from "@chakra-ui/react";
import {YouTube, Gist} from "mdx-embed";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    h3: "h3",
    p: "p",
    a: "a",
    h4: "h4",
    code: "code",
    pre: "pre",
    strong: "strong",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "JavaScript Game Development"), "\n", React.createElement(_components.h3, null, "Different Solutions for Game Loops"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://codepen.io/HunorMarton/pen/EqmyMN?editors=0010"
  }, "requestanimation frame react - codepen")), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://css-tricks.com/using-requestanimationframe-with-react-hooks/"
  }, "Request Animation frame react hook - css tricks")), "\n", React.createElement(_components.h4, null, "Simple Example ", React.createElement(_components.code, null, "requestAnimationFrame")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "function animate() {\n\n  // stuff for animating goes here\n\n  requestAnimationFrame(animate);\n}\nanimate();\t\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "requestAnimationFrame"), " wrapped in a time-out is a clean way of controlling the frame rate for vanilla.js games."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "let framesPerSecond = 10;\n\nfunction animate() {\n    setTimeout(function() {\n        requestAnimationFrame(animate);\n\n        // animating/drawing code goes here\n\n\n    }, 1000 / framesPerSecond);\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "EXAMPLE")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-JavaScript"
  }, "var promise = new Promise(function(resolve, reject){\n  resolve(5);\n});\n\npromise.then(function(data){\n  return data * 2;\n}).then(function(data){\n  return data + 20;\n}).then(function(data){\n  console.log(data);\n});\n// log 30\n")), "\n", React.createElement(_components.h3, null, "JavaScript Game Dev Bugs"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://medium.com/@dovern42/handling-multiple-key-presses-at-once-in-vanilla-javascript-for-game-controllers-6dcacae931b7"
  }, "handle multipe key presses")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://blog.sklambert.com/html5-game-tutorial-game-ui-canvas-vs-dom/"
  }, "Game Tutorial")), "\n"), "\n"), "\n", React.createElement(_components.h3, null, "Chris Courses"), "\n", React.createElement(Box, {
    mb: 6
  }, React.createElement(YouTube, {
    youTubeId: "rTVoyWu8r6g"
  })), "\n", React.createElement(Box, {
    mb: 6
  }, React.createElement(YouTube, {
    youTubeId: "4q2vvZn5aoo"
  })), "\n", React.createElement(Box, {
    mb: 6
  }, React.createElement(YouTube, {
    youTubeId: "MCVU0w73uKI"
  })), "\n", React.createElement(_components.h3, null, "Space Invaders"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://gist.github.com/dgrcode/277ea214eeceb82dbd4feecdb7a48012"
  }, "Dgrcode functional pattern for Space Invaders")), "\n"), "\n", React.createElement(Container, {
    maxW: "container.xl",
    mb: 6
  }, React.createElement(Gist, {
    gistLink: "dgrcode/277ea214eeceb82dbd4feecdb7a48012"
  })), "\n", React.createElement(_components.h2, null, "Reference"), "\n", React.createElement(_components.h3, null, "Standout Game assets"), "\n", React.createElement(_components.h3, null, "Asset Packs"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://pixelfrog-assets.itch.io/tiny-swords"
  }, "tiny swords asset pack")), "\n", React.createElement(_components.h3, null, "Single Characters"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://free-game-assets.itch.io/free-fantasy-knight"
  }, "Fantasy knight")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
